<template>
  <div class="w-100 position-relative">
    <CCard class="">
      <div class="modal-body modal-container">
        <div class="pdf-wrapper">
          <div class="pdf-page fs-16">
            <div id="pdf-2" class="pdf-content ml-3 mr-3">
              <div class="text-center fs-16">
                <strong class="bold">Mẫu C.I.4</strong><br>
                <strong>Bảng dự toán kinh phí của hoạt động xúc tiến đầu tư đề xuất thuộc chương trình xúc tiến đầu tư
                  quốc gia</strong><br>
                <p class="font-italic">(Áp dụng cho hồ sơ đề xuất thực hiện chương trình xúc tiến đầu tư quốc gia quy
                  định tại điểm b, khoản 2, Điều 92 Nghị định số 31/2021/NĐ-CP)</p>
                <hr>
              </div>
              <div style="margin-left: 5em">
                <p class="font-weight-bold text-uppercase">{{ item.coQuanChuTri }}</p>
                <hr class="ml-1" width="150px" style="border: 1px double">
              </div>
              <div class="row">
                <div class="col"></div>
                <div class="col float-right" style="margin-left: 6em">
                  <p class="font-weight font-italic">
                    {{ item.diaDiem }}, ngày {{ getDay(item.ngayVanBan) }} tháng {{ getMonth(item.ngayVanBan) }} năm
                    {{ getYear(item.ngayVanBan) }} </p>
                </div>
              </div>
              <div class="text-center">
                <b class="text-uppercase">BẢNG DỰ TOÁN KINH PHÍ TỪ CHƯƠNG TRÌNH</b> <br>
                <b class="text-uppercase">XÚC TIẾN TƯ QUỐC GIA</b> <br>
                <b class="font-italic">({{ its.tenHoatDong }})</b> <br>
                <p class="mt-0 mb-0 pt-0 pb-0">(Kèm theo Đề án số... ngày {{ item.ngayVanBan | formatDate }}/ của
                  {{ its.donViChuTriThucHien }})</p>
              </div>
              <div class="row">
                <div class="col"></div>
                <div class="col"></div>
                <div class="col float-right">
                  <p class="font-italic mb-3">Đơn vị tính: triệu đồng</p>
                </div>
              </div>
              <div class="row">
                <table class="col-12 table table-bordered table-striped">
                  <thead>
                  <tr>
                    <th class="text-center align-middle" width="50px">TT</th>
                    <th class="text-center align-middle">Nội dung công việc</th>
                    <th class="text-center align-middle" width="100px">Đơn vị tính</th>
                    <th class="text-center align-middle" width="120px">Số lượng</th>
                    <th class="text-center align-middle" width="120px">Đơn giá</th>
                    <th class="text-center align-middle" width="150px">Thành tiền</th>
                    <th class="text-center align-middle" width="120px">Ghi chú</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="text-center align-middle">
                    <td></td>
                    <td>(1)</td>
                    <td>(2)</td>
                    <td>(3)</td>
                    <td>(4)</td>
                    <td>(5) = (3)*(4)</td>
                    <td>(6)</td>
                  </tr>
                  <template v-if="data.duToanKinhPhiCTXTDTQuocGia.length">
                    <tr class="text-center align-middle" v-for="(itz) in data.duToanKinhPhiCTXTDTQuocGia"
                        v-bind:key="itz.guid">
                      <td>{{ itz.stt }}</td>
                      <td>{{ itz.noiDungCongViec }}</td>
                      <td>{{ itz.donViTinh }}</td>
                      <td>{{ Number(itz.soLuong) | formatNumber}}</td>
                      <td>
                        <span class="float-right">{{ Number(itz.donGia) | formatNumber}}</span>
                      </td>
                      <td>
                        <span class="float-right">{{Number(itz.soLuong) * Number(itz.donGia) | formatNumber}}</span>
                      </td>
                      <td></td>
                    </tr>
                    <tr class="align-middle">
                      <td></td>
                      <td><p class="font-weight-bold">Tổng số</p></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <span class="float-right">{{ tongThanhTien | formatNumber }}</span>
                      </td>
                      <td></td>
                    </tr>
                    <tr class="align-middle">
                      <td colspan="7">
                        <p class="font-weight-bold ml-5">Bằng chữ: {{ doc(tongThanhTien)}}</p>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr class="text-center align-middle">
                      <td colspan="7">Chưa có dữ liệu</td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
              <div class="col-12">
                <p class="font-italic mt-0 pt-0 pb-0">(Lưu ý: Đối với trường hợp điều chỉnh, đề nghị tạo bảng cho tất cả
                  các nội dung và chia 2 cột thể hiện trước và sau điều chỉnh. Đối với trường hợp bổ sung theo ý kiến
                  của Bộ Kế hoạch và Đầu tư như quy định tại Điểm c, Khoản 2, Điều 92 Nghị định 31/2021/NĐ-CP, đề nghị
                  tạo bảng cho tất cả các nội dung bổ sung, chia 2 cột thể hiện trước và sau điều chỉnh, và để trống cột
                  trước)</p>
              </div>
              <div class="col-12 row mt-5">
                <div class="col-5 float-left">
                </div>
                <div class="col-7 text-center">
                  <b>Người lập bảng</b> <br>
                  <span class="font-italic">(Ghi rõ họ tên, đơn vị, ký, điện thoại, email)</span>
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                </div>
              </div>
            </div>
            <div class="print-btn close-button">
              <button class="print-button" @click="print">
                <span class="print-icon"></span></button>
            </div>
          </div>
        </div>
      </div>
    </CCard>
  </div>
</template>

<script>
import { displayLabel, displayLabel2 } from '@/shared/appConstants'
import { tinhThanhService } from '@/services/tinh-thanh.service'
import { quocGiaService } from '@/services/quoc-gia.service'
import { nganhLinhVucService } from '@/services/nganh-linh-vuc.service'
import moment from 'moment'
import appConfig from '@/shared/appConfig'
import { loaiHoatDongService } from '@/services/loai-hoat-dong.service'
import { vungKinhTeService } from '@/services/vung-kinh-te.service'
import { enums } from '@/shared/enums'

export default {
  name: 'DeXuatCTXTDTQGDeAnPrint',
  data () {
    return {
      Attachments: [],
      type: 'DeXuatCTXTDTQGDeAnPrint',
      isDeleteConfirmationModalOpen: false,
      optionsNganhLinhVuc: [],
      optionsTinhThanh: [],
      optionsQuocGia: [],
      optionsLoaiHoatDong: [],
      optionsNguoiKinhPhi: [],
      optionsDiaBanKeuGoiDauTu: [],
      item: {},
      it: {},
      its: {},
      duToanKinhPhiXTDTQuocGia: [],
      tongThanhTien: 0,
      data: {
        id: 0,
        deXuatCTXTDTQuocGiaGuid: '',
        chiTietDeXuatCTXTDTQuocGiaGuid: '',
        guid: '',
        thoiGianThucHien: new Date(),
        doiTuongDuKienThamGia: '',
        quyMoHoatDong: '',
        noiDungChiTietKhac: '',
        phuongThucToChuc: '',
        danhGiaDongGop: '',
        dinhKem: '',
        dinhKem_DuToanKinhPhi: '',
        hangMucCongViecCTXTDTQuocGia: [],
        duToanKinhPhiCTXTDTQuocGia: []
      }
    }
  },
  computed: {},
  methods: {
    displayLabel,
    displayLabel2,
    getDownloadURL (url) {
      return url && (url.indexOf('http://') > -1 || url.indexOf('https://') > -1) ? url : appConfig.BASE_HOST + url
    },
    doc1so (so) {
      // eslint-disable-next-line camelcase
      const arr_chuhangdonvi = ['không', 'một', 'hai', 'ba', 'bốn', 'năm', 'sáu', 'bảy', 'tám', 'chín']
      let resualt = ''
      resualt = arr_chuhangdonvi[so]
      return resualt
    },
    doc2so (so) {
      so = so.replace(' ', '')
      // eslint-disable-next-line camelcase
      const arr_chubinhthuong = ['không', 'một', 'hai', 'ba', 'bốn', 'năm', 'sáu', 'bảy', 'tám', 'chín']
      // eslint-disable-next-line camelcase
      const arr_chuhangdonvi = ['mươi', 'mốt', 'hai', 'ba', 'bốn', 'lăm', 'sáu', 'bảy', 'tám', 'chín']
      // eslint-disable-next-line camelcase
      const arr_chuhangchuc = ['', 'mười', 'hai mươi', 'ba mươi', 'bốn mươi', 'năm mươi', 'sáu mươi', 'bảy mươi', 'tám mươi', 'chín mươi']
      let resualt = ''
      const sohangchuc = so.substr(0, 1)
      const sohangdonvi = so.substr(1, 1)
      resualt += arr_chuhangchuc[sohangchuc]
      if (sohangchuc === 1 && sohangdonvi === 1) { resualt += ' ' + arr_chubinhthuong[sohangdonvi] } else if (sohangchuc === 1 && sohangdonvi > 1) { resualt += ' ' + arr_chuhangdonvi[sohangdonvi] } else if (sohangchuc > 1 && sohangdonvi > 0) { resualt += ' ' + arr_chuhangdonvi[sohangdonvi] }

      return resualt
    },
    doc3so (so) {
      let resualt = ''
      // eslint-disable-next-line camelcase
      const arr_chubinhthuong = ['không', 'một', 'hai', 'ba', 'bốn', 'năm', 'sáu', 'bảy', 'tám', 'chín']
      const sohangtram = so.substr(0, 1)
      const sohangchuc = so.substr(1, 1)
      const sohangdonvi = so.substr(2, 1)
      resualt = arr_chubinhthuong[sohangtram] + ' trăm'
      if (sohangchuc === 0 && sohangdonvi !== 0) { resualt += ' linh ' + arr_chubinhthuong[sohangdonvi] } else if (sohangchuc !== 0) { resualt += ' ' + this.doc2so(sohangchuc + ' ' + sohangdonvi) }
      return resualt
    },
    docsonguyen (so) {
      let result = ''
      if (so !== undefined) {
        // alert(so);
        // eslint-disable-next-line camelcase
        const arr_So = [{ ty: '' }, { trieu: '' }, { nghin: '' }, { tram: '' }]
        const sochuso = so.length
        for (let i = (sochuso - 1); i >= 0; i--) {
          if ((sochuso - i) <= 3) {
            if (arr_So.tram !== undefined) { arr_So.tram = so.substr(i, 1) + arr_So.tram } else arr_So.tram = so.substr(i, 1)
          } else if ((sochuso - i) > 3 && (sochuso - i) <= 6) {
            if (arr_So.nghin !== undefined) { arr_So.nghin = so.substr(i, 1) + arr_So.nghin } else arr_So.nghin = so.substr(i, 1)
          } else if ((sochuso - i) > 6 && (sochuso - i) <= 9) {
            if (arr_So.trieu !== undefined) { arr_So.trieu = so.substr(i, 1) + arr_So.trieu } else arr_So.trieu = so.substr(i, 1)
          } else {
            if (arr_So.ty !== undefined) { arr_So.ty = so.substr(i, 1) + arr_So.ty } else arr_So.ty = so.substr(i, 1)
          }
          // console.log(arr_So);
        }

        if (arr_So.ty > 0) { result += this.doc(arr_So.ty) + ' tỷ' }
        if (arr_So.trieu > 0) {
          if (arr_So.trieu.length >= 3 || arr_So.ty > 0) { result += ' ' + this.doc3so(arr_So.trieu) + ' triệu' } else if (arr_So.trieu.length >= 2) { result += ' ' + this.doc2so(arr_So.trieu) + ' triệu' } else result += ' ' + this.doc1so(arr_So.trieu) + ' triệu'
        }
        if (arr_So.nghin > 0) {
          if (arr_So.nghin.length >= 3 || arr_So.trieu > 0) { result += ' ' + this.doc3so(arr_So.nghin) + ' nghìn' } else if (arr_So.nghin.length >= 2) { result += ' ' + this.doc2so(arr_So.nghin) + ' nghìn' } else result += ' ' + this.doc1so(arr_So.nghin) + ' nghìn'
        }
        if (arr_So.tram > 0) {
          if (arr_So.tram.length >= 3 || arr_So.nghin > 0) { result += ' ' + this.doc3so(arr_So.tram) } else if (arr_So.tram.length >= 2) { result += ' ' + this.doc2so(arr_So.tram) } else result += ' ' + this.doc1so(arr_So.tram)
        }
      }
      return result
    },
    doc (so) {
      const kytuthapphan = ','
      let result = ''
      if (so !== undefined) {
        so = ' ' + so + ' '
        so = so.trim()
        const cautrucso = so.split(kytuthapphan)
        if (cautrucso[0] !== undefined) {
          result += this.docsonguyen(cautrucso[0])
        }
        if (cautrucso[1] !== undefined) {
          // alert(this.docsonguyen(cautrucso[1]));
          result += ' phẩy ' + this.docsonguyen(cautrucso[1])
        }
      }

      return result
    },
    getDate: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('DD/MM/YYYY') : '',
    getDay: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('DD') : '',
    getMonth: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('MM') : '',
    getYear: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('YYYY') : '',
    back () {
      this.$router.push({ path: '/de-xuat-ct-xtdt' })
    },
    getLocalStorage () {
      const jsonData = localStorage.getItem('de_xuat_ct_xtdt_quoc_gia_du_toan')
      const data = JSON.parse(jsonData)
      console.log(data)
      if (data) {
        this.item = data.item
        this.its = data.its
        this.data = data.data
        this.data.hangMucCongViecCTXTDTQuocGia = data.hangMucCongViecCTXTDTQuocGia
        this.data.duToanKinhPhiCTXTDTQuocGia = data.duToanKinhPhiXTDTQuocGia
        this.countTongTien()
      }
    },
    print () {
      window.print()
    },
    countTongTien () {
      if (this.data.duToanKinhPhiCTXTDTQuocGia.length) {
        let tong = 0
        for (const i in this.data.duToanKinhPhiCTXTDTQuocGia) {
          tong += Number(this.data.duToanKinhPhiCTXTDTQuocGia[i].soLuong) * Number(this.data.duToanKinhPhiCTXTDTQuocGia[i].donGia)
        }
        this.tongThanhTien = tong
      }
    },
    getLineThrough (trangThai) {
      if (trangThai === 4) {
        return 'text-decoration-line: line-through'
      } else {
        return ''
      }
    }
  },
  async created () {
    if (this.$route.params.id) {
      await this.getItem(this.$route.params.id)
    } else {
      await this.getLocalStorage()
    }
    if (this.item.dinhKem) this.Attachments = JSON.parse(this.item.dinhKem)
    this.optionsTinhThanh = await tinhThanhService.getAll()
    this.optionsQuocGia = await quocGiaService.getAll()
    this.optionsNganhLinhVuc = await nganhLinhVucService.getAll()
    this.optionsLoaiHoatDong = await loaiHoatDongService.getAll()
    this.optionsNguoiKinhPhi = enums.nguonKinhPhi
    this.optionsDiaBanKeuGoiDauTu = await vungKinhTeService.getAll()
  }
}
</script>

<style scoped>

</style>
